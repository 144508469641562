import { useAuth0 } from "@auth0/auth0-react";
import { DefaultProgress } from "esa-components";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { ReadUser, isIpRestricted, AppError, DecodedJWT } from "esa-core";
import { getConfig } from "config";
import urls from "const/urls";
import filterAvailableUsers from "logic/filterAvailableUsers";
import IpRestrictionPage from "pages/IpRestrictionPage";
import { useLoginContext } from "routes/protections/RequireLoginContext";

const Context = createContext<ReadUser | null>(null);

export const RequireCurrentUser = (): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const loginContext = useLoginContext();
  const navigate = useNavigate();
  const {
    api: { audience },
  } = getConfig();

  const [isLoading, setIsLoading] = useState(true);
  const [isMfaAuthenticated, setIsMfaAuthenticated] = useState(false);

  useEffect(() => {
    const checkMfaAuthentication = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience,
        },
      });
      const decodedData = jwtDecode<Record<string, unknown>>(accessToken);
      const decodedToken = DecodedJWT.parse({
        claims: decodedData,
      });
      const mfaAuthenticated = decodedToken.claims.mfa ?? false;
      setIsMfaAuthenticated(mfaAuthenticated);
      setIsLoading(false);
    };
    checkMfaAuthentication();
  }, [getAccessTokenSilently]);

  const onClickReturnToLogin = (): void => {
    navigate(urls.logout);
  };

  if (isLoading) {
    return <DefaultProgress />;
  }

  const users = filterAvailableUsers(loginContext.users);

  // ユーザが存在して currentUser が要求される場合、一度 logout して currentUser を選択させる
  if (users.length > 0 && loginContext.currentUserUid === null) {
    return <Navigate to={urls.logout} />;
  }

  const currentUser = users.find(
    (user) => user.userUid === loginContext.currentUserUid,
  );

  // ユーザが存在しない場合は、初期登録フローに流す
  if (!currentUser) {
    return <Navigate to={urls.register} />;
  }

  // IP アドレス制限
  if (
    loginContext.clientIp != null &&
    !isMfaAuthenticated &&
    isIpRestricted(currentUser.ipAddressList, loginContext.clientIp)
  ) {
    return (
      <IpRestrictionPage
        clientIp={loginContext.clientIp}
        onClickReturnToLogin={onClickReturnToLogin}
      />
    );
  }

  return (
    <Context.Provider value={currentUser}>
      <Outlet />
    </Context.Provider>
  );
};

export const useCurrentUser = (): ReadUser => {
  const currentUser = useContext(Context);

  if (!currentUser) {
    throw new AppError(
      "useCurrentUser must be called inside `<RequireCurrentUser>`",
      {},
    );
  }

  return currentUser;
};
