import { DefaultProgress } from "esa-components";
import { HttpError, useModel, KeyedMutator } from "esa-hooks";
import { createContext, useCallback, useContext, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LoginContext, AppError } from "esa-core";
import CommonErrorPanel from "components/ErrorPanel/CommonErrorPanel";
import TermsOfServiceForLoggedInUser from "components/ServiceOfTerms/TermsOfServiceForLoggedInUser";
import { getConfig } from "config";
import urls from "const/urls";

const Context = createContext<{
  context: LoginContext | null;
  mutate: KeyedMutator<LoginContext>;
} | null>(null);

export const RequireLoginContext = (): JSX.Element => {
  const config = getConfig();
  const { isLoading, error, model, mutate } = useModel({
    api: {
      audience: config.api.audience,
      baseUrl: config.api.url,
      path: "/login_context",
    },
    schema: LoginContext,
  });
  const providerValue = useMemo(
    (): {
      context: LoginContext | null;
      mutate: KeyedMutator<LoginContext>;
    } => ({ context: model, mutate }),
    [model, mutate],
  );
  const onClickReset = useCallback(() => {
    window.location.reload();
  }, []);

  if (isLoading) {
    return <DefaultProgress />;
  }

  // Auth0 ユーザが esa-api に存在しない場合、一度ログアウトさせる
  if (error instanceof HttpError && error.status === 404) {
    return <Navigate to={urls.logout} />;
  }

  if (error) {
    return <CommonErrorPanel error={error} onClickReset={onClickReset} />;
  }

  return (
    <Context.Provider value={providerValue}>
      {!model.isAgreeTerms && (
        <TermsOfServiceForLoggedInUser {...providerValue} />
      )}
      <Outlet />
    </Context.Provider>
  );
};

export const useLoginContext = (): LoginContext => {
  const loginContext = useContext(Context);

  if (loginContext?.context == null) {
    throw new AppError(
      "useLoginContext must be called inside `<RequireLoginContext>`",
      {},
    );
  }

  return loginContext.context;
};

export const useLoginContextMutate = (): KeyedMutator<LoginContext> => {
  const loginContext = useContext(Context);

  if (loginContext == null) {
    throw new AppError(
      "useLoginContext must be called inside `<RequireLoginContext>`",
      {},
    );
  }

  return loginContext.mutate;
};
