// Ref: https://gitlab.com/eseikatsu/ebone-webapps/apps/-/blob/984c81348b4af11823218f0aca53390065edc2e9/packages/@app/rent/frontend/src/Pages/error/CommonErrorComponent.tsx
import { ErrorAnnounce } from "@e-seikatsu/design-system";
import { Box, Container, Link, Typography } from "@mui/material";
import { ReactNode } from "react";
import { getConfig } from "config";

type ErrorPanelProps = {
  heading: ReactNode;
  subHeading?: ReactNode;
  action?: ReactNode;
  errorCode?: string | number;
};

const config = getConfig();

const ErrorPanel = ({
  heading,
  subHeading,
  action,
  errorCode,
}: ErrorPanelProps): JSX.Element => (
  <Container
    sx={{
      height: "100%",
      paddingTop: "60px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <ErrorAnnounce
      title={heading}
      comment={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mb={2} color="text.secondary">
            {subHeading}
            <br />
            いい生活アカウントのサービス情報は
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={config.statusPage.url}
              sx={{ display: "inline-flex" }}
            >
              ステータスページ
            </Link>
            をご確認ください。
          </Box>
        </Box>
      }
      note={
        errorCode != null && (
          <Box
            mb={2}
            color="text.secondary"
            sx={{
              textAlign: "center",
            }}
          >
            エラーコード：{errorCode}
          </Box>
        )
      }
    />
    {action}
    <Box
      pt={4}
      pb={2}
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Typography variant="body1" color="text.secondary">
        ©e-Seikatsu Co.,Ltd.
      </Typography>
    </Box>
  </Container>
);

export default ErrorPanel;
