import {
  StyledEngineProvider,
  ThemeProvider,
  CssBaseline,
  Box,
} from "@mui/material";
import { DefaultProgress } from "esa-components";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import getViewportHeight from "utils/getViewPortHeight";
import AppRoutes from "./routes";
import theme from "./Theme";

const fullscreenHeight = getViewportHeight(100);

const App = (): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <Box height={fullscreenHeight}>
            <DefaultProgress />
          </Box>
        }
      >
        <CssBaseline />
        <Box height={fullscreenHeight}>
          <AppRoutes />
        </Box>
        <ToastContainer position="top-center" theme="colored" />
      </Suspense>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
