import { HttpError, useEffectOnce } from "esa-hooks";
import Component, {
  CommonErrorPanelProps,
} from "components/ErrorPanel/CommonErrorPanel";
import { useVersion } from "hooks/useVersion";
import { useSafeEsaLogger } from "routes/protections/RequireEsaLogger";

const CommonErrorPanel = (props: CommonErrorPanelProps): JSX.Element => {
  const logger = useSafeEsaLogger();
  const { refreshRequired } = useVersion();
  const { error } = props;

  useEffectOnce(() => {
    // API 側でエラーログが出力されているエラーはフロントではロギングしない
    if (error instanceof HttpError) {
      return;
    }
    if (logger != null) {
      logger.error("Unexpected error", { e: error, refreshRequired });
    }
  });

  return <Component refreshRequired={refreshRequired} {...props} />;
};

export default CommonErrorPanel;
