import { ReadUser } from "esa-core";

/**
 * ログイン可能なユーザを計算する
 *
 * @param users
 */
const filterAvailableUsers = (users: ReadUser[]): ReadUser[] =>
  users.filter((user) => user.enableEntryFeature);

export default filterAvailableUsers;
