import { parseISO } from "date-fns";
import { useSWR } from "esa-hooks";
import { createContext, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import {
  EboneMaintenance,
  EboneSchedule,
  EsaMaintenance,
  EsaSchedule,
  AppError,
} from "esa-core";
import { getConfig } from "config";
import MaintenancePage, { EsaMaintenanceState } from "pages/MaintenancePage";

export type MaintenanceStateHook =
  | {
      isLoading: true;
      error: null;
      state: null;
    }
  | {
      isLoading: false;
      error: Error;
      state: null;
    }
  | {
      isLoading: false;
      error: null;
      state: MaintenanceState;
    };

export type EboneMaintenanceState = {
  isMaintenance: boolean;
  schedule: {
    from: Date | null;
    to: Date | null;
  };
};

export type MaintenanceState = {
  ebone: EboneMaintenanceState;
  esa: EsaMaintenanceState;
};

const MaintenanceStateContext = createContext<MaintenanceStateHook | null>(
  null,
);

const stateRefreshInterval = 1000 * 60 * 5;

const config = getConfig();
const isOneEnabled = config.featureFlag.oneInterlocking;

export const MaintenanceStateProvider = (): JSX.Element => {
  const {
    data: eboneMaintenanceData,
    error: eboneMaintenanceError,
    isLoading: isEboneMaintenanceLoading,
  } = useSWR(
    isOneEnabled ? config.ebone.maintenanceUrl : null,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: stateRefreshInterval },
  );

  const {
    data: eboneScheduleData,
    error: eboneScheduleError,
    isLoading: isEboneScheduleLoading,
  } = useSWR(
    isOneEnabled ? config.ebone.scheduleUrl : null,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: stateRefreshInterval, revalidateOnFocus: false },
  );

  const {
    data: esaMaintenanceData,
    error: esaMaintenanceError,
    isLoading: isEsaMaintenanceLoading,
  } = useSWR(
    config.esa.maintenanceUrl,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: stateRefreshInterval },
  );

  const {
    data: esaScheduleData,
    error: esaScheduleError,
    isLoading: isEsaScheduleLoading,
  } = useSWR(
    config.esa.scheduleUrl,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: stateRefreshInterval, revalidateOnFocus: false },
  );

  const state = useMemo((): MaintenanceStateHook => {
    if (
      (isOneEnabled && (isEboneScheduleLoading || isEboneMaintenanceLoading)) ||
      isEsaScheduleLoading ||
      isEsaMaintenanceLoading
    ) {
      return {
        isLoading: true,
        error: null,
        state: null,
      };
    }
    if (eboneMaintenanceError != null) {
      return {
        isLoading: false,
        error: eboneMaintenanceError,
        state: null,
      };
    }
    if (eboneScheduleError != null) {
      return {
        isLoading: false,
        error: eboneScheduleError,
        state: null,
      };
    }
    if (esaMaintenanceError != null) {
      return {
        isLoading: false,
        error: esaMaintenanceError,
        state: null,
      };
    }
    if (esaScheduleError != null) {
      return {
        isLoading: false,
        error: esaScheduleError,
        state: null,
      };
    }

    const esaMaintenance = EsaMaintenance.safeParse(esaMaintenanceData);
    const esaSchedule = EsaSchedule.safeParse(esaScheduleData);
    if (!esaMaintenance.success) {
      return {
        isLoading: false,
        error: esaMaintenance.error,
        state: null,
      };
    }
    if (!esaSchedule.success) {
      return {
        isLoading: false,
        error: esaSchedule.error,
        state: null,
      };
    }

    if (!isOneEnabled) {
      return {
        isLoading: false,
        error: null,
        state: {
          ebone: {
            isMaintenance: false,
            schedule: {
              from: null,
              to: null,
            },
          },
          esa: {
            isMaintenance: esaMaintenance.data.isMaintenance,
            schedule: {
              from: parseISO(esaSchedule.data.from),
              to: parseISO(esaSchedule.data.to),
            },
          },
        },
      };
    }

    const eboneMaintenance = EboneMaintenance.safeParse(eboneMaintenanceData);
    const eboneSchedule = EboneSchedule.safeParse(eboneScheduleData);
    if (!eboneMaintenance.success) {
      return {
        isLoading: false,
        error: eboneMaintenance.error,
        state: null,
      };
    }
    if (!eboneSchedule.success) {
      return {
        isLoading: false,
        error: eboneSchedule.error,
        state: null,
      };
    }

    return {
      isLoading: false,
      error: null,
      state: {
        ebone: {
          isMaintenance: eboneMaintenance.data.prd.isMaintenance,
          schedule: {
            from: parseISO(eboneSchedule.data.prd.from),
            to: parseISO(eboneSchedule.data.prd.to),
          },
        },
        esa: {
          isMaintenance: esaMaintenance.data.isMaintenance,
          schedule: {
            from: parseISO(esaSchedule.data.from),
            to: parseISO(esaSchedule.data.to),
          },
        },
      },
    };
  }, [
    isEboneScheduleLoading,
    isEboneMaintenanceLoading,
    isEsaScheduleLoading,
    isEsaMaintenanceLoading,
    eboneMaintenanceError,
    eboneScheduleError,
    esaMaintenanceError,
    esaScheduleError,
    esaMaintenanceData,
    esaScheduleData,
    eboneMaintenanceData,
    eboneScheduleData,
  ]);

  if (state.state != null && state.state.esa.isMaintenance) {
    return <MaintenancePage maintenanceState={state.state.esa} />;
  }

  return (
    <MaintenanceStateContext.Provider value={state}>
      <Outlet />
    </MaintenanceStateContext.Provider>
  );
};

export const useMaintenanceState = (): MaintenanceStateHook => {
  const ctx = useContext(MaintenanceStateContext);

  if (ctx == null) {
    throw new AppError(
      "useMaintenanceState must be called inside `<MaintenanceStateProvider>`",
      {},
    );
  }

  return ctx;
};
