import { useSWR } from "esa-hooks";
import { diff as semverDiff, valid as semverValid } from "semver";
import { getConfig } from "config";

const checkRefreshVersionInterval = 1000 * 5;

type Version = {
  version: string;
};

export type VersionState =
  | {
      isLoading: true;
      error: null;
      currentVersion: string;
      latestVersion: null;
      refreshRequired: false;
    }
  | {
      isLoading: false;
      error: Error;
      currentVersion: string;
      latestVersion: null;
      refreshRequired: false;
    }
  | {
      isLoading: false;
      error: null;
      currentVersion: string;
      latestVersion: string;
      refreshRequired: boolean;
    };

export const useVersion = (): VersionState => {
  const config = getConfig();

  const { data: latest, error } = useSWR<Version>(
    `${config.app.url}/latest-version.json`,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: checkRefreshVersionInterval },
  );

  const currentVersion = config.app.version;

  if (error != null) {
    return {
      isLoading: false,
      error,
      currentVersion,
      latestVersion: null,
      refreshRequired: false,
    };
  }

  if (!latest) {
    return {
      isLoading: true,
      error: null,
      currentVersion,
      latestVersion: null,
      refreshRequired: false,
    };
  }

  const latestVersion = latest.version;

  const isValidCurrentVersion = semverValid(currentVersion) != null;
  const isValidLatestVersion = semverValid(latestVersion) != null;

  if (isValidCurrentVersion && isValidLatestVersion) {
    const diff = semverDiff(currentVersion, latestVersion);
    if (diff != null) {
      return {
        isLoading: false,
        error: null,
        currentVersion,
        latestVersion,
        refreshRequired: true,
      };
    }
  }

  return {
    isLoading: false,
    error: null,
    currentVersion,
    latestVersion,
    refreshRequired: false,
  };
};
