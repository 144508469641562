import { EsaApiProvider as Provider } from "esa-hooks";
import { ReactNode, useMemo } from "react";
import { getConfig } from "config";

const EsaApiProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const config = useMemo(() => getConfig(), []);

  return (
    <Provider
      audience={config.api.audience}
      baseUrl={config.api.url}
      customHeaders={{
        "Esa-App-Version": config.app.version,
      }}
    >
      {children}
    </Provider>
  );
};
export default EsaApiProvider;
