import { DefaultProgress } from "esa-components";
import { KeyedMutator } from "esa-hooks";
import { LoginContext } from "esa-core";
import Component from "features/Entries/components/TermsOfServiceDialog";
import { useUpdateProviderUser } from "features/SwitchDomain/apis/updateProviderUser";

type TermsOfServiceForLoggedInUserProps = {
  context: LoginContext | null;
  mutate: KeyedMutator<LoginContext>;
};

const TermsOfServiceForLoggedInUser = ({
  context,
  mutate,
}: TermsOfServiceForLoggedInUserProps): JSX.Element => {
  const { update } = useUpdateProviderUser();

  if (!context || !update) {
    return <DefaultProgress />;
  }

  const agreeTerms = async (): Promise<void> => {
    await update({
      providerUserUid: context.providerUserUid,
      isAgreeTerms: true,
    });
    await mutate({
      ...context,
      isAgreeTerms: true,
    });
  };

  return (
    <Component open={!context.isAgreeTerms} isLoggedIn onSubmit={agreeTerms} />
  );
};

export default TermsOfServiceForLoggedInUser;
