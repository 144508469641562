import { ComponentType, useCallback } from "react";
import { FallbackProps } from "react-error-boundary";
import { urls } from "const";
import CommonErrorPanel from "containers/ErrorPanel/CommonErrorPanel";

const ErrorPage: ComponentType<FallbackProps> = ({ error }) => {
  const onClickLogout = useCallback(() => {
    window.location.href = urls.logout;
  }, []);
  const onClickReset = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <CommonErrorPanel
      error={error}
      onClickLogout={onClickLogout}
      onClickReset={onClickReset}
    />
  );
};

export default ErrorPage;
