import { DefaultProgress } from "esa-components";
import { useApiClient } from "esa-hooks";
import { createContext, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { AppError } from "esa-core";
import CommonErrorPanel from "components/ErrorPanel/CommonErrorPanel";
import { getConfig } from "config";
import { useLoginContext } from "routes/protections/RequireLoginContext";
import EsaLogger from "services/EsaLogger";
import { IEsaLogger } from "services/IEsaLogger";

const Context = createContext<IEsaLogger | null>(null);

export const RequireEsaLogger = (): JSX.Element => {
  const {
    api: { audience, url },
  } = getConfig();

  const { isLoading, apiClient, error } = useApiClient({
    audience,
    baseUrl: url,
  });
  const loginContext = useLoginContext();

  const providerValue = useMemo(
    (): EsaLogger | null =>
      apiClient === null
        ? null
        : new EsaLogger(loginContext.providerUserUid, apiClient),

    [loginContext.providerUserUid, apiClient],
  );

  if (isLoading) {
    return <DefaultProgress />;
  }

  if (error) {
    return <CommonErrorPanel error={error} />;
  }

  return (
    <Context.Provider value={providerValue}>
      <Outlet />
    </Context.Provider>
  );
};

export const useEsaLogger = (): IEsaLogger => {
  const logger = useContext(Context);

  if (!logger) {
    throw new AppError("useLogger must be called inside `<RequireLogger>`", {});
  }

  return logger;
};

export const useSafeEsaLogger = (): IEsaLogger | null => useContext(Context);
