export default {
  root: "/",
  login: "/login",
  logout: "/logout",
  entry: "/entry",
  signup: "/signup",
  maintenance: "/maintenance/*",
  acceptInvitation: "/invitations/:invitationUid/accept",
  register: "/register",
  newPasswordChangeTicket: "/password-change-tickets/new",
  activateEmailChangeTicket:
    "/email-change-tickets/:emailChangeTicketUid/activate",
  editProfile: "/profile/edit",
  editDomain: "/domain/edit",
  organizations: "/organizations",
  members: "/members",
  editMember: "/members/:userUid/edit",
  approvals: "/approvals",
  integrationPlans: "/integration/plans",
  newIntegrationPlan: "/integration/plans/new",
  editIntegrationPlan: "/integration/plans/:planUid/edit",
  progressIntegrationPlan: "/integration/plans/:planUid/progress",
  roles: "/roles",
  switchDomain: "/switch-domain",
  editTakkenLicense: "/takken-license/edit",
  useInformation: "/customer/usage",
  activateActivationTicket:
    "/customers/:customerId/activation-tickets/:activationTicketUid/activate",
};
