import { useApiClient } from "esa-hooks";
import { ProviderUser, UpdateProviderUser } from "esa-core";
import { getConfig } from "config";

export type UpdateProviderUserHook =
  | {
      isLoading: true;
      error: null;
      update: null;
    }
  | {
      isLoading: false;
      error: Error;
      update: null;
    }
  | {
      isLoading: false;
      error: null;
      update: (providerUser: UpdateProviderUser) => Promise<ProviderUser>;
    };
export const useUpdateProviderUser = (): UpdateProviderUserHook => {
  const config = getConfig();
  const {
    isLoading,
    apiClient,
    error: authError,
  } = useApiClient({
    audience: config.api.audience,
    baseUrl: config.api.url,
  });

  if (isLoading) {
    return {
      isLoading: true,
      error: null,
      update: null,
    };
  }
  if (authError != null) {
    return {
      isLoading: false,
      error: authError,
      update: null,
    };
  }

  const update = async (
    providerUser: UpdateProviderUser,
  ): Promise<ProviderUser> => {
    const parsed = UpdateProviderUser.safeParse(providerUser);

    if (!parsed.success) {
      throw parsed.error;
    }

    return apiClient.put({
      path: `/provider_users/${parsed.data.providerUserUid}`,
      body: JSON.stringify(parsed.data),
    });
  };

  return {
    isLoading: false,
    error: null,
    update,
  };
};
