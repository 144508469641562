export default {
  serviceList: "/service",
  domainForm: "/domain",
  organizationForm: "/organization",
  memberList: "/member",
  memberDetail: "/member/:userUid",
  profileForm: "/profile",
  requestPending: "/requestPending",
  accountRegistration: "/accountRegistration",
  signUp: "/signUp",
  signOut: "/signOut",
};
