import { Box } from "@mui/material";
import { format, isAfter, isBefore } from "date-fns";
import { SimpleLayout } from "esa-components";
import { ReactNode } from "react";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";

export type EsaMaintenanceState = {
  isMaintenance: boolean;
  schedule: {
    from: Date | null;
    to: Date | null;
  };
};

type MaintenancePageProps = {
  maintenanceState: EsaMaintenanceState;
};

const Maintenance = ({ children }: { children?: ReactNode }): JSX.Element => (
  <SimpleLayout>
    <ErrorPanel
      heading="現在いい生活アカウントはメンテナンス中です"
      subHeading={
        <div>
          <div>
            現在メンテナンス中のため、一時的にサービスをご利用いただけません。
          </div>
          <div>ご利用のお客様にはご不便をおかけして申し訳ございませんが、</div>
          <div>メンテナンス完了までしばらくお待ちください。</div>
          <Box mt={2}>{children}</Box>
        </div>
      }
    />
  </SimpleLayout>
);

const MaintenancePage = ({
  maintenanceState,
}: MaintenancePageProps): JSX.Element => {
  const { from } = maintenanceState.schedule;
  const { to } = maintenanceState.schedule;

  if (from == null || to == null) {
    return <Maintenance />;
  }

  const now = new Date();

  if (isAfter(now, from) && isBefore(now, to)) {
    const fromText = format(from, "MM/dd HH:mm");
    const toText = format(to, "MM/dd HH:mm");

    return (
      <Maintenance>
        <Box>{`開始 ${fromText} ~ 終了 ${toText}`}</Box>
      </Maintenance>
    );
  }

  return <Maintenance />;
};

export default MaintenancePage;
