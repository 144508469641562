export const ESA_SUPPORT_SITE_BASE_URL = "https://esa.support.e-seikatsu.info";
export const SQUARE_SUPPORT_SITE_BASE_URL =
  "https://square.support.e-seikatsu.info";

export const SupportSiteName = {
  top: "サポートサイト_トップページ",
  requestAutoApproval: "サポートサイト_自動承認",
  phoneIdentification: "サポートサイト_電話番号による在籍確認",
  inquiry: "サポートサイト_問い合わせ",
  terms: "サポートサイト_利用規約",
  shozokudantai: "サポートサイト_所属団体",
  createAccount: "サポートサイト_アカウント作成",
  request: "サポートサイト_会社所属リクエスト",
  dataIntegration: "サポートサイト_データ統合",
} as const;

export type SupportSiteName =
  (typeof SupportSiteName)[keyof typeof SupportSiteName];

type SupportSite = {
  name: SupportSiteName;
  url: string;
};

export const SUPPORT_SITE_PAGES = {
  top: `${ESA_SUPPORT_SITE_BASE_URL}/`,
  requestAutoApproval: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/request-auto-approval/`,
  phoneIdentification: `${ESA_SUPPORT_SITE_BASE_URL}/phone-identification/`,
  inquiry: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/inquiry/`,
  esaTerms: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/kiyaku/`,
  squareTerms: `${SQUARE_SUPPORT_SITE_BASE_URL}/knowledge-base/kiyaku/`,
  shozokuDantai: `${SQUARE_SUPPORT_SITE_BASE_URL}/knowledge-base/shozokudantai/`,
  messages: `${SQUARE_SUPPORT_SITE_BASE_URL}/knowledge-base/メッセージ機能・取引先管理とは何ですか？/`,
  createAccount: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/create-account/`,
  request: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/request/`,
  dataIntegration: `${ESA_SUPPORT_SITE_BASE_URL}/knowledge-base/activation/`,
} as const;

export const supportSiteDict = {
  top: {
    name: SupportSiteName.top,
    url: SUPPORT_SITE_PAGES.top,
  },
  requestAutoApproval: {
    name: SupportSiteName.requestAutoApproval,
    url: SUPPORT_SITE_PAGES.requestAutoApproval,
  },
  phoneIdentification: {
    name: SupportSiteName.phoneIdentification,
    url: SUPPORT_SITE_PAGES.phoneIdentification,
  },
  inquiry: {
    name: SupportSiteName.inquiry,
    url: SUPPORT_SITE_PAGES.inquiry,
  },
  esaTerms: {
    name: SupportSiteName.terms,
    url: SUPPORT_SITE_PAGES.esaTerms,
  },
  squareTerms: {
    name: SupportSiteName.terms,
    url: SUPPORT_SITE_PAGES.squareTerms,
  },
  shozokudantai: {
    name: SupportSiteName.shozokudantai,
    url: SUPPORT_SITE_PAGES.shozokuDantai,
  },
  createAccount: {
    name: SupportSiteName.createAccount,
    url: SUPPORT_SITE_PAGES.createAccount,
  },
  request: {
    name: SupportSiteName.request,
    url: SUPPORT_SITE_PAGES.request,
  },
  dataIntegration: {
    name: SupportSiteName.dataIntegration,
    url: SUPPORT_SITE_PAGES.dataIntegration,
  },
};

export type SupportSitePagesKey = keyof typeof supportSiteDict;

export const SupportSitePageslist: SupportSite[] = [
  supportSiteDict.top,
  supportSiteDict.requestAutoApproval,
  supportSiteDict.phoneIdentification,
  supportSiteDict.inquiry,
  supportSiteDict.esaTerms,
  supportSiteDict.squareTerms,
  supportSiteDict.shozokudantai,
  supportSiteDict.createAccount,
  supportSiteDict.request,
  supportSiteDict.dataIntegration,
];

export const openSupportSitePages = (
  supportSite: SupportSitePagesKey,
): void => {
  const { url } = supportSiteDict[supportSite];
  window.open(url, "_blank");
};
