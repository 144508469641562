import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { getConfig } from "config";
import { urls } from "const";
import legacyUrls from "const/legacyUrls";
import ErrorPage from "pages/ErrorPage";
import Auth0Provider from "providers/Auth0Provider";
import EsaApiProvider from "providers/EsaApiProvider";
import OneApiProvider from "providers/OneApiProvider";
import { MaintenanceStateProvider } from "routes/protections/MaintenanceStateProvider";
import RefreshVersionProvider from "routes/protections/RefreshVersionProvider";
import RequireAuth0Login from "routes/protections/RequireAuth0Login";
import { RequireCurrentUser } from "routes/protections/RequireCurrentUser";
import { RequireEsaLogger } from "routes/protections/RequireEsaLogger";
import { RequireLoginContext } from "./protections/RequireLoginContext";

// not require authentication
const AcceptInvitationPage = lazy(
  () => import("../features/Invitations/routes/AcceptInvitationPage"),
);
const LogoutPage = lazy(() => import("../pages/LogoutPage"));
const EntryPage = lazy(() => import("../features/Entries/routes/EntryPage"));
const SignUpPage = lazy(() => import("../features/SignUp/routes/SignUpPage"));
const RegisterPage = lazy(
  () => import("../features/Register/routes/RegisterPage"),
);

// require authentication
const DefaultLayout = lazy(() => import("../containers/Layout/DefaultLayout"));
const MemberDetailPage = lazy(
  () => import("../features/Users/routes/MemberDetailPage"),
);
const MemberListPage = lazy(
  () => import("../features/Users/routes/MemberListPage"),
);
const ApprovalListPage = lazy(
  () => import("../features/Users/routes/ApprovalListPage"),
);
const UserProfilePage = lazy(
  () => import("../features/Users/routes/UserProfilePage"),
);
const DomainDetailPage = lazy(
  () => import("../features/Domains/routes/DomainDetailPage"),
);
const OrganizationListPage = lazy(
  () => import("../features/Organizations/routes/OrganizationListPage"),
);
const ServicePage = lazy(
  () => import("../features/Service/routes/ServiceListsPage"),
);

const IntegrationPlanListPage = lazy(
  () => import("../features/Integration/routes/IntegrationPlanListPage"),
);
const EditIntegrationPlanPage = lazy(
  () => import("../features/Integration/routes/EditIntegrationPlanPage"),
);
const NewIntegrationPlanPage = lazy(
  () => import("../features/Integration/routes/NewIntegrationPlanPage"),
);
const IntegrationProgressPage = lazy(
  () => import("../features/Integration/routes/IntegrationProgressPage"),
);
const RoleListPage = lazy(
  () => import("../features/Roles/routes/RoleListPage"),
);
const SwitchDomainPage = lazy(
  () => import("features/SwitchDomain/routes/SwitchDomainPage"),
);
const ActivateEmailChangePage = lazy(
  () => import("features/Users/routes/ActivateEmailChangePage"),
);
const EditTakkenLicensePage = lazy(
  () => import("features/Domains/routes/EditTakkenLicensePage"),
);
const UseInformationPage = lazy(
  () => import("features/UserInformation/routes/UseInformationPage"),
);
const ActivationPage = lazy(
  () => import("features/Activation/routes/ActivationPage"),
);

const LegacyMemberDetailPage = lazy(
  () => import("features/Legacy/routes/MemberDetailPage"),
);

const config = getConfig();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Auth0Provider>
            <EsaApiProvider>
              <OneApiProvider>
                <Outlet />
              </OneApiProvider>
            </EsaApiProvider>
          </Auth0Provider>
        </ErrorBoundary>
      }
    >
      <Route element={<RefreshVersionProvider />}>
        <Route path={urls.entry} element={<EntryPage />} />
        <Route path={urls.logout} element={<LogoutPage />} />
        <Route element={<MaintenanceStateProvider />}>
          <Route path={urls.signup} element={<SignUpPage />} />
          <Route
            path={urls.acceptInvitation}
            element={<AcceptInvitationPage />}
          />
          <Route element={<RequireAuth0Login />}>
            <Route
              path={urls.progressIntegrationPlan}
              element={<IntegrationProgressPage />}
            />
            <Route element={<RequireLoginContext />}>
              <Route element={<RequireEsaLogger />}>
                <Route path={urls.register} element={<RegisterPage />} />
                <Route
                  path={urls.activateEmailChangeTicket}
                  element={<ActivateEmailChangePage />}
                />
                <Route
                  path={urls.activateActivationTicket}
                  element={<ActivationPage />}
                />
                <Route element={<RequireCurrentUser />}>
                  <Route
                    path={urls.newIntegrationPlan}
                    element={<NewIntegrationPlanPage />}
                  />
                  <Route
                    path={urls.editIntegrationPlan}
                    element={<EditIntegrationPlanPage />}
                  />
                  <Route
                    path={urls.switchDomain}
                    element={<SwitchDomainPage />}
                  />
                  <Route
                    path={urls.editTakkenLicense}
                    element={<EditTakkenLicensePage />}
                  />
                  <Route path={urls.root} element={<DefaultLayout />}>
                    <Route index element={<ServicePage />} />
                    <Route
                      path={urls.editProfile}
                      element={<UserProfilePage />}
                    />
                    <Route
                      path={urls.editDomain}
                      element={<DomainDetailPage />}
                    />
                    <Route
                      path={urls.organizations}
                      element={<OrganizationListPage />}
                    />
                    <Route path={urls.members} element={<MemberListPage />} />
                    <Route
                      path={urls.editMember}
                      element={<MemberDetailPage />}
                    />
                    <Route
                      path={urls.approvals}
                      element={<ApprovalListPage />}
                    />
                    {config.featureFlag.oneInterlocking && (
                      <Route
                        path={urls.integrationPlans}
                        element={<IntegrationPlanListPage />}
                      />
                    )}
                    <Route path={urls.roles} element={<RoleListPage />} />
                    {config.featureFlag.oneInterlocking && (
                      <Route
                        path={urls.useInformation}
                        element={<UseInformationPage />}
                      />
                    )}
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/*
        旧系のいい生活アカウントのパスをリダイレクトさせる
        参照するパスが存在しなくなったら削除する
       */}

          <Route
            path={legacyUrls.serviceList}
            element={<Navigate to={urls.root} />}
          />
          <Route
            path={legacyUrls.domainForm}
            element={<Navigate to={urls.editDomain} />}
          />
          <Route
            path={legacyUrls.organizationForm}
            element={<Navigate to={urls.organizations} />}
          />
          <Route
            path={legacyUrls.memberList}
            element={<Navigate to={urls.members} />}
          />
          <Route
            path={legacyUrls.memberDetail}
            element={<LegacyMemberDetailPage />}
          />
          <Route
            path={legacyUrls.profileForm}
            element={<Navigate to={urls.editProfile} />}
          />
          <Route
            path={legacyUrls.requestPending}
            element={<Navigate to={urls.register} />}
          />
          <Route
            path={legacyUrls.accountRegistration}
            element={<Navigate to={urls.register} />}
          />
          <Route
            path={legacyUrls.signUp}
            element={<Navigate to={urls.signup} />}
          />
          <Route
            path={legacyUrls.signOut}
            element={<Navigate to={urls.logout} />}
          />
          <Route path="/*" element={<Navigate to={urls.root} />} />
        </Route>
      </Route>
    </Route>,
  ),
);

const AppRoutes = (): JSX.Element => <RouterProvider router={router} />;

export default AppRoutes;
