import { IApiClient } from "esa-hooks";
import { getConfig } from "config";
import ActivityEvent from "event";
import { IEsaLogger } from "services/IEsaLogger";

type LogSeverity = "ERROR" | "WARN" | "INFO" | "DEBUG";
type ProviderUserLogType = "activity" | "console";

const consoleBySeverity = {
  INFO: console.info,
  WARN: console.warn,
  ERROR: console.error,
  DEBUG: console.debug,
};

class EsaLogger implements IEsaLogger {
  constructor(
    private readonly providerUserUid: string,
    private readonly apiClient: IApiClient,
  ) {}

  request<T>(type: ProviderUserLogType, data: T): void {
    const metadata = {
      userAgent: navigator.userAgent,
      href: window.location.href,
      path: window.location.pathname,
      search: window.location.search,
    };

    this.apiClient
      .post({
        path: `/provider_users/${this.providerUserUid}/logs`,
        body: JSON.stringify({
          type,
          event: {
            ...data,
            metadata,
          },
        }),
      })
      .catch((e) => {
        console.error(e);
      });
  }

  console<T>(severity: LogSeverity, message: string, data: T): void {
    // 通常のログは常にコンソールにもログを書き出す
    consoleBySeverity[severity](message, data);

    const { logger } = getConfig();

    if (
      !logger.enabled ||
      // エラー以外はログ送信を行わない
      severity !== "ERROR"
    ) {
      return;
    }

    const event = {
      message,
      data,
    };

    this.request("console", event);
  }

  activity(event: ActivityEvent): void {
    const { logger } = getConfig();

    if (!logger.enabled) {
      // ログが無効の場合のみ、アクティビティイベントはコンソールに書き出す
      console.info(event);
      return;
    }

    this.request("activity", event);
  }

  debug<T>(message: string, data: T): void {
    this.console("DEBUG", message, data);
  }

  info<T>(message: string, data: T): void {
    this.console("INFO", message, data);
  }

  warn<T>(message: string, data: T): void {
    this.console("WARN", message, data);
  }

  error<T>(message: string, data: T): void {
    this.console("ERROR", message, data);
  }
}

export default EsaLogger;
