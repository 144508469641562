import { AppState, Auth0Provider as Provider } from "@auth0/auth0-react";
import { ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "config";

const Auth0Provider = ({ children }: { children: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const config = useMemo(() => getConfig(), []);

  const onRedirectCallback = (appState?: AppState): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      authorizationParams={{
        redirect_uri: config.app.url,
        audience: config.api.audience,
      }}
      cacheLocation={config.auth0.cacheLocation}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Provider>
  );
};
export default Auth0Provider;
